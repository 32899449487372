<template>
  <div class="main-content">

    <div class="text-content">

      <h1>Terms of Services</h1>

      <!-- TODO -->
      <h2>Accounts</h2>
      <p>Free or paid accounts are nontransferable and non-refundable. You must accurately provide requested information at signup as well as a valid email address. We establish limits concerning use of the service and may modify these limits at any time without notice. We reserve the right to suspend or terminate service to anyone, at any time, without notice if we feel our terms are being violated or integrity of service is being disrupted. Your use of our site and software is at your own risk. We can not guarantee the security of any data passing through our networks. You agree to be responsible for safeguarding your data, including usernames, passwords and content.</p>

      <h2>Transactional Emails</h2>

      <p>On signup we automatically send an account validation email and during usage emails indicating the expiration of an account. All other notifications and alerts concerning click stats, event monitoring and account limits are for convenience purposes and can be disabled.
      </p>

      <h2>Disclaimer</h2>

      <p>PlanInk.Me is an online tool to create Kanban Boards or Markup/HTML documents that can be kept private or shared with the world. A shared document does not indicate our endorsement of, or affiliation with other websites. We are not responsible for the content of shared documents or linked websites.</p>
      <p>PlanInk.Me by TheProgress is provided without warranty of any kind. There is no guarantee that it will be available at any given time, and no guarantee that the site or service will not be subject to interruptions. We reserve the right to amend or change these policies, terms and conditions at any time and without prior notice.
      </p>

      <h2>User created content on the website</h2>

      <p>
      User created content on this website may be reviewed by TheProgress as well as your conduct and content for compliance with these Terms and our Rules, and we reserve the right to remove any violating content.
      The website owner reserves the right to delete or disable content alleged to be infringing the intellectual property rights of others, and to terminate accounts of repeat infringers. We respond to notices of alleged copyright infringement if they comply with the law;
      </p>

      <h2>Rights and Ownership</h2>

      <p>You retain your rights to any content you submit, post or display on or through this website.
      </p>

      <p>
      Unless otherwise agreed in writing, by submitting, posting, or displaying content on or through this website via the SHARE functionality, you grant the owner of this website a nonexclusive, royalty-free, worldwide, fully paid, and sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, publicly perform and display your content and any name, username or likeness provided in connection with your content in all media formats and distribution methods now known or later developed on the website.
      </p>
      <p>This license is needed because you own your content and we could otherwise not display the content you want to share with others. Content that is NOT SHARED does NOT fall under the above mentioned license</p>

      <h2>Limitation of Liability</h2>

      <p>ALL DIRECT OR INDIRECT RISKS RELATED TO THE USE OF THIS SITE IS BORNE ENTIRELY BY YOU, THE USER. THE OWNER OF THIS WEBSITE SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST PROFITS, USE, GOODWILL, DATA LOSS OR OTHER INTANGIBLE LOSSES, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND (EVEN IF URL SHORTENER BY THEPROGRESS OR PARTNERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM THE USE OR THE INABILITY TO USE THIS WEBSITES SERVICES. UNDER NO CIRCUMSTANCES SHALL SUCH LIABILITY EXCEED THE AMOUNT PAID TO THE OWNER OF THE WEBSITE.
      </p>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },

    methods: {
    }
  }
</script>
