<template>
  <div class="main-content">
    <div class="text-content">

      <h1>This is an about page</h1>

      <p>Every commercial website in Germany needs to have an Impressum (Legal Disclosure, there is no perfect translation…), it’s the law. This is mine.</p>

      <h2>
        Contact Information
      </h2>
      <p>This site is run by Jonathan Bletscher. If you want something taken off this website, just ask. Cooperation is preferred over litigation.</p>
      <figure>
          <img src="@/assets/contact.png"/>
      </figure>

      <h2>
        Privacy Policy
      </h2>
      <p>This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). That service uses cookies to help me analyze how users use this website. The information generated by Google Analytics about your use of the website will be anonymized, then transmitted to and stored by Google on servers in the United States. On behalf of the website provider, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage to the website provider. Google will not associate your IP address with any other data held by Google.</p>
      <p>If you do not agree to collecting the data and helping me improving my content I will be sad but respect your opinion ;). You can opt-out or opt-in of the google analytics data collection via clicking the below links:
        <br>
        <a href="#" @click="disableTracking">Disable Google Analytics</a>
        <br>
        <a href="#" @click="enableTracking">Enable Google Analytics</a>
      </p>

      <p>In case you sign up for our newsletter, the email is stored on Mailchimp. We use Mailchimp as our marketing platform. By confirming the double opt-in you acknowledge that your information will be transferred to Mailchimp for processing. learn more about Mailchimp’s privacy practices

      <a href="https://mailchimp.com/legal/privacy/">here</a>.

      If you disagree with this please do not sign up! You can also easily unsubscribe from our newsletter via the unsubscribe in any email we send.</p>

      <h2>Log collection</h2>

      <p>This website also collects server logs for debugging purposes. The logs are anonymized and do not contain any personal information.</p>

      <h2>Data collection while using Planink services</h2>

      <p>If you use PlanInk services and have an account to create KanbanBoards or Documents, then we process your data at an international data hosting center (namely GCP).
      Therefore data can be stored in data centers outside of the EU /EEA. We have
      taken appropriate measures in order to ensure that the service providers/data centers chosen,
      comply with the data protection laws and, hence, ensures the security of your personal data.</p>

      <h2>Your right to information, correction, deletion, and disclosure of your data</h2>

      <p>In accordance with legal provisions, you have the right to correct, delete, and block your personal data.
      Additionally, you have the right to obtain the following information from us at any time:
      (as appropriate) which of your personal data we store,
      what our purpose for storing this data is, as well as requesting the origin and recipient,
      or recipient category of this data.</p>
      <p>Such inquiries can be directed to the following contact information:</p>
      <figure>
          <img src="@/assets/contact.png"/>
      </figure>

    </div>
  </div>
</template>

<script>
  // import { useState, isTracking, isReady } from "vue-gtag-next";

  export default {
    data() {
      return {

      }
    },

    methods: {
      enableTracking() {
        // console.log('Tracking enabled');
        this.$cookies.set('enableGA', true)
        // this.$ga.enable();
        // var state = useState()
        // state.isEnabled.value = true;
        console.log('Tracking: ',true);
        this.$store.state.analytics.setAnalyticsCollectionEnabled(true)
        // console.log('GA ready:' ,isReady);
      },
      disableTracking() {
        // console.log('Tracking disabled');
        this.$cookies.set('enableGA', false)
        // this.$ga.disable();
        // var state = useState()
        // state.isEnabled.value = false;
        console.log('Tracking: ',false);
        this.$store.state.analytics.setAnalyticsCollectionEnabled(false)
        // console.log('GA ready:' ,isReady);
      },
    }
  }
</script>
